import type { Category } from '$lib/types';

export function getInitials(name: string | null | undefined): string {
	if (name === undefined || name === null) {
		return '?';
	}

	return name
		.split(' ')
		.map((word) => word.charAt(0).toUpperCase())
		.join('');
}

export function decorateTitle(title: string) {
	return `${title} - Resonance`;
}

export function descriptionPreview(description: string) {
	return description.length > 160 ? description.slice(0, 160) + '...' : description;
}

export function getPageSettings(searchParams: URLSearchParams): { page: number; limit: number } {
	let page = Number(searchParams.get('page')) || 0;
	let limit = Number(searchParams.get('limit')) || 10;
	return { page, limit };
}
